import cns from "classnames";

import pages from "@Settings/pages";
import Container from "@Components/Container";
import Highlights from "@Components/Highlights";
import HighlightsList from "@Components/HighlightsList";
import SectionProducts from "@Components/SectionProducts";
import { useGetPageDataPage } from "@Hooks/api/page";

import SectionCollections from "./SectionCollections";
import SectionDescription from "./SectionDescription";
import SectionMain from "./SectionMain";
import SectionPopular from "./SectionPopular";
import SectionSlider from "./SectionSlider";
import styles from "./index.module.scss";

const Page: React.FC = () => {
    const dataPage = useGetPageDataPage();

    return (
        <>
            <main className={styles.component}>
                <Container>
                    <SectionMain
                        className={cns(
                            styles.component__section,
                            styles.component__section_main
                        )}
                    />

                    <HighlightsList
                        className={cns(
                            styles.component__section,
                            styles.component__section_highlights
                        )}
                    />

                    <SectionProducts
                        className={styles.component__section}
                        title="Бестселлеры"
                        link={{
                            link: `${pages.catalog.link}?show=hit`,
                        }}
                        products={dataPage?.products?.hit ?? []}
                    />

                    <SectionCollections className={styles.component__section} />
                    <SectionPopular className={styles.component__section} />

                    <SectionSlider
                        className={cns(
                            styles.component__section,
                            styles.component__section_slider
                        )}
                    />

                    <SectionProducts
                        className={styles.component__section}
                        title="Новинки"
                        link={{
                            link: `${pages.catalog.link}?show=new`,
                        }}
                        products={dataPage?.products?.new ?? []}
                    />

                    <SectionDescription className={styles.component__section} />
                </Container>
            </main>

            <Highlights />
        </>
    );
};

export default Page;
