import cns from "classnames";
import { useState } from "react";
import AnimateHeight from "react-animate-height";

import { usePageDataMain } from "@Hooks/api/page";
import useWindowSize from "@Hooks/window-size";

import styles from "./index.module.scss";

type SectionProps = {
    className?: string;
};

const Section: React.FC<SectionProps> = ({ className }) => {
    const { texts, pageTitle } = usePageDataMain();

    const [textIsVisible, setTextIsVisible] = useState<boolean>(false);
    const [height, setHeight] = useState<"auto" | number>(66);

    const windowSize = useWindowSize();

    if (!texts[0]) {
        return null;
    }

    const handleClickButton = (e: React.MouseEvent) => {
        e.preventDefault();

        setTextIsVisible(!textIsVisible);
        setHeight(height === "auto" ? 66 : "auto");
    };

    return (
        <div className={cns(className, styles.component)}>
            <div className={styles.component__content}>
                <h2
                    className={cns(
                        styles.component__title,
                        "text text_h5 text_uppercase"
                    )}
                >
                    {pageTitle}
                </h2>

                <AnimateHeight
                    className={cns(styles.component__text, "text text_p3", {
                        isVisible: textIsVisible,
                    })}
                    duration={windowSize.isDesktop ? 500 : 0}
                    height={height}
                >
                    <div
                        dangerouslySetInnerHTML={{
                            __html: texts[0],
                        }}
                    ></div>
                </AnimateHeight>

                <button
                    className={cns(styles.component__button, "link")}
                    onClick={handleClickButton}
                    aria-expanded={height === "auto"}
                >
                    {textIsVisible ? "Скрыть" : "Читать дальше"}
                </button>
            </div>
        </div>
    );
};

export default Section;
