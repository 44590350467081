import cns from "classnames";

import SliderMain from "@Components/SliderMain";
import { useGetPageDataBannersTop } from "@Hooks/api/page";

import styles from "./index.module.scss";

type SectionProps = {
    className?: string;
};

const Section: React.FC<SectionProps> = ({ className }) => {
    const dataBannersTop = useGetPageDataBannersTop();

    if (!dataBannersTop.length) {
        return null;
    }

    return (
        <div className={cns(className, styles.component)}>
            <SliderMain slides={dataBannersTop} />
        </div>
    );
};

export default Section;
