import cns from "classnames";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { FreeMode, Keyboard, Mousewheel, Scrollbar } from "swiper/modules";
import { Swiper } from "swiper/types";

import { openHighlights } from "@Components/Highlights";
import Slider from "@Components/Slider";
import Image from "@Components/ui/Image";
import { useGetPageDataHighlights } from "@Hooks/api/page";
import useWindowSize from "@Hooks/window-size";
import { HighlightResponse } from "@Types/api";

import styles from "./index.module.scss";

export type HighlightsListProps = {
    className?: string;
};

const HighlightsList: React.FC<HighlightsListProps> = ({ className }) => {
    const dataHighlights: HighlightResponse = useGetPageDataHighlights();

    const dispatch = useDispatch();
    const windowSize = useWindowSize();

    const refList = useRef<HTMLDivElement>(null);

    const [swiper, setSwiper] = useState<Swiper | null>(null);
    const [isSpaceBetween, setIsSpaceBetween] = useState<boolean>(false);

    useEffect(() => {
        swiper?.update();
    }, [swiper]);

    useEffect(() => {
        setIsSpaceBetween(
            (refList.current?.clientWidth ?? 0) >
                (refList.current?.parentElement?.clientWidth ?? 0) / 1.75
        );
    }, [dataHighlights, windowSize.width, swiper]);

    if (!dataHighlights?.length) {
        return null;
    }

    const handleItemClick = (id: number) => {
        dispatch(openHighlights({ id }));
    };

    return (
        <div className={cns(className, styles.component)}>
            <Slider
                className={styles.component__slider}
                classNamesSlide={styles.component__slide}
                modules={[FreeMode, Keyboard, Mousewheel, Scrollbar]}
                slidesPerView={"auto"}
                cssMode
                freeMode
                scrollbar={{
                    draggable: true,
                    el: null,
                }}
                breakpoints={{
                    1025: {
                        cssMode: false,
                    },
                }}
                slides={[
                    <div
                        key={1}
                        ref={refList}
                        className={cns(styles.component__list, {
                            isSpaceBetween,
                        })}
                    >
                        {dataHighlights.map((item, itemIndex) => (
                            <div
                                key={itemIndex}
                                className={styles.component__item}
                                onClick={() => handleItemClick(item.id)}
                            >
                                <div className={styles.component__imageWrapper}>
                                    <div className={styles.component__image}>
                                        <Image
                                            src={item.image}
                                            alt=""
                                            fill
                                            size="10vw"
                                            sizeTablet="25vw"
                                        />
                                    </div>
                                </div>
                                <div
                                    className={cns(
                                        styles.component__name,
                                        "text text_p4 text_center"
                                    )}
                                >
                                    {item.name}
                                </div>
                            </div>
                        ))}
                    </div>,
                ]}
                onSwiper={setSwiper}
            />
        </div>
    );
};

export default HighlightsList;
