import cns from "classnames";
import { useMemo } from "react";

import pages from "@Settings/pages";
import CardCategory from "@Components/CardCategory";
import SectionSlider from "@Components/SectionSlider";
import { useGetStoreCategories } from "@Hooks/api/store";
import { Category } from "@Types/api";

import styles from "./index.module.scss";

type SectionProps = {
    className?: string;
};

const Section: React.FC<SectionProps> = ({ className }) => {
    const storeCategories = useGetStoreCategories();
    const categoriesPopular = useMemo(
        () => storeCategories.getPopular(),
        [storeCategories]
    );

    if (!categoriesPopular.length) {
        return null;
    }

    return (
        <div className={cns(className, styles.component)}>
            <SectionSlider
                classNameSlide={styles.component__slide}
                title="Популярные категории"
                slides={categoriesPopular.map(
                    (card: Category, cardIndex: number) => (
                        <CardCategory
                            key={cardIndex}
                            link={pages.category.link(card.PATH)}
                            image={card.PICTURE_URL as string}
                            text={card.NAME}
                        />
                    )
                )}
            />
        </div>
    );
};

export default Section;
