import cns from "classnames";
import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Autoplay, Keyboard, Mousewheel, Pagination } from "swiper/modules";
import { Swiper as SwiperType } from "swiper/types";

import Slider from "@Components/Slider";
import Button from "@Components/ui/Button";
import Image from "@Components/ui/Image";
import { HighlightItem } from "@Types/api";

import { closeHighlights } from "..";
import styles from "./index.module.scss";

export type ItemProps = HighlightItem & {
    parentSlider: SwiperType | null;
    currentId: HighlightItem["id"];
    isPaused: boolean;
    setIsPaused: (isPaused: boolean) => void;
};

const STORY_DURATION = 10000;
const STORY_TIMEOUT_DELAY = 500;

let buttonChangeSlideTimeout: ReturnType<typeof setTimeout> | number = 0;

const Item: React.FC<ItemProps> = ({
    id,
    name,
    link,
    image,
    items,
    parentSlider,
    currentId,
    isPaused,
    setIsPaused,
}) => {
    const dispatch = useDispatch();

    const [swiper, setSwiper] = useState<SwiperType | null>(null);

    const refPagination = useRef<HTMLDivElement>(null);
    const refAutoplayProgress = useRef<number>(1);

    const setAutoplayVideoDelay = (duration?: number | null) => {
        if (swiper && swiper?.params.autoplay) {
            // @ts-ignore
            swiper.params.autoplay.delay = duration ?? STORY_DURATION;
        }
    };

    const videos = useMemo(() => {
        const list: {
            id: number;
            el: HTMLVideoElement;
            duration: number | null;
        }[] = [];

        if (swiper) {
            swiper.slides.forEach((slide, slideIndex) => {
                const $video = slide.querySelector("video");

                if ($video) {
                    const indexInList = list.length;

                    list.push({
                        id: slideIndex,
                        el: $video,
                        duration: $video.duration * 1000 || null,
                    });

                    if (isNaN($video.duration)) {
                        $video.addEventListener(
                            "loadedmetadata",
                            () => {
                                list[indexInList].duration =
                                    $video.duration * 1000;

                                if (slideIndex === 0) {
                                    setAutoplayVideoDelay(
                                        list[indexInList].duration
                                    );
                                }
                            },
                            { once: true }
                        );
                    } else {
                        if (slideIndex === 0) {
                            setAutoplayVideoDelay(list[indexInList].duration);
                        }
                    }
                }
            });
        }

        return list;
    }, [swiper]);

    const changeVideoState = (withReset: boolean = true) => {
        videos.forEach(video => {
            if (
                video.id === swiper?.activeIndex &&
                currentId === id &&
                !isPaused
            ) {
                if (withReset) {
                    setAutoplayVideoDelay(video.duration);

                    video.el.currentTime = 0;
                }

                if (video.el.paused) {
                    video.el.play();
                }
            } else {
                if (!video.el.paused) {
                    video.el.pause();
                }
            }
        });
    };

    const handleButtonChangeSlideMouseDown = (
        e: React.MouseEvent | React.TouchEvent
    ) => {
        e.stopPropagation();

        clearTimeout(buttonChangeSlideTimeout);

        buttonChangeSlideTimeout = setTimeout(() => {
            setIsPaused(true);
        }, STORY_TIMEOUT_DELAY);
    };

    const handleButtonChangeSlideMouseUp = (
        e: React.MouseEvent | React.TouchEvent,
        direction: "prev" | "next"
    ) => {
        e.preventDefault();
        e.stopPropagation();

        clearTimeout(buttonChangeSlideTimeout);

        if (isPaused) {
            setIsPaused(false);

            return;
        }

        if (direction === "prev") {
            if (swiper?.isBeginning) {
                parentSlider?.slidePrev();
            } else {
                swiper?.slidePrev();
            }
        } else if (direction === "next") {
            if (swiper?.isEnd) {
                parentSlider?.slideNext();
            } else {
                swiper?.slideNext();
            }
        }
    };

    const handleSlideChange = () => {
        refAutoplayProgress.current = 1;

        if (swiper && swiper.params.autoplay) {
            swiper.autoplay.stop();

            // @ts-ignore
            swiper.params.autoplay.delay = STORY_DURATION;

            swiper.autoplay.start();
            swiper.autoplay.resume();
        }

        changeVideoState();
    };

    const handleAutoplayTimeLeft = (
        swp: SwiperType,
        timeLeft: number,
        percentage: number
    ) => {
        if (percentage <= 0 && refAutoplayProgress.current === 1) {
            return;
        }

        if (refAutoplayProgress.current <= 0) {
            if (swiper?.isEnd && currentId === id) {
                swiper?.autoplay.stop();

                if (parentSlider?.isEnd) {
                    dispatch(closeHighlights());
                } else {
                    parentSlider?.slideNext();
                }
            }
        }

        refPagination.current?.style.setProperty(
            "--progress",
            `${1 - refAutoplayProgress.current}`
        );

        refAutoplayProgress.current = Math.min(Math.max(percentage, 0), 1);
    };

    useEffect(() => {
        swiper?.update();

        if (currentId !== id) {
            swiper?.autoplay.stop();
        }
    }, [swiper]);

    useEffect(() => {
        if (currentId === id) {
            refAutoplayProgress.current = 1;

            swiper?.slideTo(0, 0, false);

            swiper?.autoplay.start();
            swiper?.autoplay.resume();
        } else {
            swiper?.autoplay.stop();
        }

        changeVideoState();
    }, [currentId]);

    useEffect(() => {
        if (isPaused) {
            swiper?.autoplay.pause();
        } else {
            swiper?.autoplay.resume();
        }

        changeVideoState(false);
    }, [isPaused]);

    return (
        <div className={cns(styles.component, { isPaused })}>
            <header className={styles.component__header}>
                <div
                    ref={refPagination}
                    className={styles.component__pagination}
                ></div>

                <div className={styles.component__info}>
                    <div className={styles.component__imagePreview}>
                        <Image
                            src={image}
                            alt=""
                            fill
                            size="5vw"
                            sizeTablet="15vw"
                        />
                    </div>
                    <div className={styles.component__name}>{name}</div>
                </div>
            </header>

            <Slider
                className={styles.component__slider}
                classNamesSlide={styles.component__slide}
                modules={[Autoplay, Keyboard, Mousewheel, Pagination]}
                autoplay={{
                    delay: STORY_DURATION,
                    disableOnInteraction: false,
                    stopOnLastSlide: true,
                }}
                allowTouchMove={false}
                shortSwipes={false}
                longSwipes={false}
                loop={false}
                nested
                // todo: check how it works without preloading
                // lazyPreloadPrevNext={2}
                pagination={{
                    el: refPagination.current,
                    bulletClass: styles.component__paginationLine,
                    bulletActiveClass: "isActive",
                    currentClass: "isCurrent",
                    clickable: true,
                }}
                slides={items.map((item, i) => {
                    if (item.type === "image") {
                        return (
                            <Image
                                key={i}
                                src={item.src}
                                alt=""
                                fill
                                size="35vw"
                                sizeTablet="100vw"
                                withLoader
                            />
                        );
                    } else if (item.type === "video") {
                        return (
                            <video key={i} playsInline>
                                <source src={item.src} />
                            </video>
                        );
                    }

                    return null;
                })}
                onSwiper={setSwiper}
                onSlideChange={handleSlideChange}
                onAutoplayTimeLeft={handleAutoplayTimeLeft}
            />

            <button
                className={cns(
                    styles.component__buttonChangeSlide,
                    styles.component__buttonChangeSlide_prev
                )}
                type="button"
                aria-label="Предыдущая история"
                onMouseDown={handleButtonChangeSlideMouseDown}
                onTouchStart={handleButtonChangeSlideMouseDown}
                onMouseUp={e => handleButtonChangeSlideMouseUp(e, "prev")}
                onTouchEnd={e => handleButtonChangeSlideMouseUp(e, "prev")}
            ></button>

            <button
                className={cns(
                    styles.component__buttonChangeSlide,
                    styles.component__buttonChangeSlide_next
                )}
                type="button"
                aria-label="Следующая история"
                onMouseDown={handleButtonChangeSlideMouseDown}
                onTouchStart={handleButtonChangeSlideMouseDown}
                onMouseUp={e => handleButtonChangeSlideMouseUp(e, "next")}
                onTouchEnd={e => handleButtonChangeSlideMouseUp(e, "next")}
            ></button>

            {link && (
                <footer className={styles.component__footer}>
                    <Button
                        className={styles.component__buttonLink}
                        href={link.href}
                        simple
                        blank
                        size="md"
                        color="tLight"
                    >
                        {link.text}
                    </Button>
                </footer>
            )}
        </div>
    );
};

export default Item;
