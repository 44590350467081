import cns from "classnames";
import React, { useEffect, useRef, useState } from "react";
import {
    Autoplay,
    Keyboard,
    Mousewheel,
    Navigation,
    Pagination,
} from "swiper/modules";
import { Swiper } from "swiper/types";

import Slider from "@Components/Slider";
import Button from "@Components/ui/Button";
import Image from "@Components/ui/Image";
import SvgIcon from "@Components/ui/SvgIcon";
import Video from "@Components/ui/Video";
import { ContentBannersItem } from "@Types/api";

import styles from "./index.module.scss";

export type SliderMainProps = {
    slides: ContentBannersItem[];
};

const SliderMain: React.FC<SliderMainProps> = ({ slides }) => {
    const [swiper, setSwiper] = useState<Swiper | null>(null);

    const refArrowPrev = useRef<HTMLButtonElement>(null);
    const refArrowNext = useRef<HTMLButtonElement>(null);
    const refPagination = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (swiper) {
            swiper.update();
        }
    }, [swiper]);

    if (!slides || !slides.length) {
        return null;
    }

    return (
        <div className={styles.component}>
            <Slider
                className={styles.component__slider}
                classNamesSlide={styles.component__slide}
                modules={[
                    Autoplay,
                    Keyboard,
                    Mousewheel,
                    Navigation,
                    Pagination,
                ]}
                autoplay={{
                    delay: 20000,
                    disableOnInteraction: false,
                }}
                loop
                edgeSwipeDetection={true}
                navigation={{
                    prevEl: refArrowPrev.current,
                    nextEl: refArrowNext.current,
                }}
                pagination={{
                    el: refPagination.current,
                    bulletClass: styles.component__paginationLine,
                    bulletActiveClass: "isActive",
                    currentClass: "isCurrent",
                    clickable: true,
                }}
                onAutoplayTimeLeft={(swiper, timeLeft, percentage) => {
                    if (refPagination.current) {
                        refPagination.current.style.setProperty(
                            "--progress",
                            `${1 - Math.max(0, Math.min(1, percentage))}`
                        );
                    }
                }}
                slides={slides.map((slide, slideIndex) => (
                    <React.Fragment key={slideIndex}>
                        <div className={styles.component__media}>
                            {slide.media.map((media, mediaIndex) =>
                                media.src ? (
                                    <div
                                        key={mediaIndex}
                                        className={styles.component__mediaItem}
                                    >
                                        {media.type === "video" && (
                                            <Video
                                                src={media.src}
                                                autoPlayInView
                                                loop
                                                muted
                                                playsInline
                                                preload="none"
                                            />
                                        )}

                                        {media.type === "image" && (
                                            <Image
                                                src={media.src}
                                                alt=""
                                                fill
                                                size={
                                                    slide.media.length === 1
                                                        ? "100vw"
                                                        : "50vw"
                                                }
                                                sizeTablet="100vw"
                                            />
                                        )}
                                    </div>
                                ) : null
                            )}
                        </div>

                        <div className={styles.component__content}>
                            <div
                                className={cns(
                                    styles.component__title,
                                    "text text_h1 text_uppercase text_center"
                                )}
                            >
                                {slide.title}
                            </div>

                            {slide.text && (
                                <div
                                    className={cns(
                                        styles.component__text,
                                        "text text_p1 text_center"
                                    )}
                                >
                                    {slide.text}
                                </div>
                            )}

                            {slide.button && (
                                <Button
                                    className={styles.component__button}
                                    href={slide.button.link}
                                    size="md"
                                    color="tLight"
                                >
                                    {slide.button.text}
                                </Button>
                            )}
                        </div>
                    </React.Fragment>
                ))}
                onSwiper={setSwiper}
            />

            <button
                ref={refArrowPrev}
                className={cns(
                    styles.component__arrow,
                    styles.component__arrow_prev
                )}
                type="button"
            >
                <SvgIcon icon="angle-left-md" />
            </button>

            <button
                ref={refArrowNext}
                className={cns(
                    styles.component__arrow,
                    styles.component__arrow_next
                )}
                type="button"
            >
                <SvgIcon icon="angle-right-md" />
            </button>

            <div
                ref={refPagination}
                className={styles.component__pagination}
            ></div>
        </div>
    );
};

export default SliderMain;
