import cns from "classnames";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import Swiper from "swiper";
import { EffectCreative, EffectCube, Navigation } from "swiper/modules";
import { Swiper as SwiperType } from "swiper/types";

import Popup from "@Components/Popup";
import SvgIcon from "@Components/ui/SvgIcon";
import { useGetPageDataHighlights } from "@Hooks/api/page";
import useWindowSize from "@Hooks/window-size";
import { close, getExtra, getIsOpen, open } from "@Redux/popup";
import { HighlightResponse } from "@Types/api";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/effect-cube";
import "swiper/css/effect-creative";

import Item from "./Item";
import styles from "./index.module.scss";

export type HighlightsProps = {};

type extraProps = {
    id: number;
};

export const popupId = "highlights";

export const openHighlights = (extra: extraProps) =>
    open({ id: popupId, extra });

export const closeHighlights = () => close(popupId);

const Highlights: React.FC<HighlightsProps> = () => {
    const dataHighlights: HighlightResponse = useGetPageDataHighlights();

    const isOpen = useSelector(getIsOpen(popupId));
    const extra: extraProps | null = useSelector(getExtra(popupId));
    const windowSize = useWindowSize();

    const [isReady, setIsReady] = useState<boolean>(false);
    const [swiper, setSwiper] = useState<SwiperType | null>(null);
    const [currentId, setCurrentId] = useState<number>(dataHighlights[0]?.id);
    const [isPaused, setIsPaused] = useState<boolean>(false);

    const refSlider = useRef<HTMLDivElement>(null);
    const refArrowPrev = useRef<HTMLButtonElement>(null);
    const refArrowNext = useRef<HTMLButtonElement>(null);

    useEffect(() => {
        swiper?.destroy();

        if (refSlider.current) {
            setSwiper(
                new Swiper(refSlider.current, {
                    modules: [Navigation, EffectCreative, EffectCube],
                    centeredSlides: !windowSize.isMobile,
                    slidesPerView: windowSize.isMobile ? 1 : "auto",
                    slideToClickedSlide: !windowSize.isMobile,
                    preventInteractionOnTransition: true,
                    touchMoveStopPropagation: true,
                    simulateTouch: false,
                    edgeSwipeDetection: true,
                    effect: windowSize.isMobile ? "cube" : "creative",
                    cubeEffect: {
                        shadow: false,
                    },
                    creativeEffect: windowSize.isMobile
                        ? undefined
                        : {
                              limitProgress: dataHighlights.length,
                              next: {
                                  translate: [
                                      `calc(100% + calc(var(--vh, 1vh) * ${
                                          (22 / 900) * 100
                                      }))`,
                                      0,
                                      0,
                                  ],
                                  scale: 326 / 428,
                                  origin: "right center",
                              },
                              prev: {
                                  translate: [
                                      `calc(-100% - calc(var(--vh, 1vh) * ${
                                          (22 / 900) * 100
                                      }))`,
                                      0,
                                      0,
                                  ],
                                  scale: 326 / 428,
                                  origin: "left center",
                              },
                          },
                    navigation: {
                        prevEl: refArrowPrev.current,
                        nextEl: refArrowNext.current,
                    },
                    on: {
                        slideChange(swr) {
                            setCurrentId(dataHighlights[swr?.activeIndex]?.id);
                        },
                    },
                })
            );
        }

        if (!isOpen) {
            setIsReady(false);
        }
    }, [isOpen, windowSize.isMobile]);

    useEffect(() => {
        if (!isOpen || !swiper || !extra) {
            return;
        }

        const slideIndex = dataHighlights.findIndex(
            item => item.id === extra.id
        );

        if (typeof slideIndex !== "undefined") {
            swiper.slideTo(slideIndex, 0, false);
        }

        setCurrentId(dataHighlights[slideIndex ?? 0].id);
        setIsReady(true);
    }, [swiper]);

    return (
        <Popup
            className={cns(styles.component, { isReady, isPaused })}
            classNameContainer={styles.component__container}
            classNameButtonClose={styles.component__buttonClose}
            id={popupId}
            closeIcon="close"
        >
            <div
                ref={refSlider}
                className={cns(styles.component__slider, "swiper")}
            >
                <div
                    className={cns(styles.component__wrapper, "swiper-wrapper")}
                >
                    {dataHighlights.map(item => (
                        <div
                            key={item.id}
                            className={cns(
                                styles.component__slide,
                                "swiper-slide"
                            )}
                        >
                            <Item
                                {...item}
                                parentSlider={swiper}
                                currentId={currentId}
                                isPaused={isPaused}
                                setIsPaused={setIsPaused}
                            />
                        </div>
                    ))}
                </div>
            </div>

            <button
                ref={refArrowPrev}
                className={cns(
                    styles.component__arrow,
                    styles.component__arrow_prev,
                    "notForMobile"
                )}
                type="button"
                aria-label="Предыдущие моменты"
            >
                <SvgIcon icon="angle-left-md" />
            </button>

            <button
                ref={refArrowNext}
                className={cns(
                    styles.component__arrow,
                    styles.component__arrow_next,
                    "notForMobile"
                )}
                type="button"
                aria-label="Следующие моменты"
            >
                <SvgIcon icon="angle-right-md" />
            </button>
        </Popup>
    );
};

export default Highlights;
