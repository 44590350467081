import type { NextPageWithLayout } from "@Types/common";

import Seo from "@Components/Seo";
import { useGetPageDataPage } from "@Hooks/api/page";
import PageMain from "@Pages/main";
import { withCommonProps } from "@Utils/datalayer";

export const getStaticProps = withCommonProps(async (ctx, client) => {
    const bannersTop = await client.content.getBanners("top");
    const bannersMiddle = await client.content.getBanners("middle");
    const bannersBottom = await client.content.getBanners("bottom");
    const highlights = await client.content.getHighlights();
    const page = await client.pages.getPage("main");

    const productsHit = await client.catalog.getProducts({
        page_count: 20,
        HIT: "Y",
    });

    const productsNew = await client.catalog.getProducts({
        page_count: 20,
        NEW: "Y",
    });

    // if (!pageData) {
    //     return {
    //         notFound: true,
    //     };
    // }

    return {
        props: {
            data: {
                bannersTop,
                bannersMiddle,
                bannersBottom,
                highlights,
                page: {
                    ...page,
                    products: {
                        hit: productsHit.products,
                        new: productsNew.products,
                    },
                },
            },
        },
        revalidate: 120,
    };
});

const Page: NextPageWithLayout = () => {
    const page = useGetPageDataPage();

    return (
        <>
            <Seo pageSeo={page?.seo} />
            <PageMain />
        </>
    );
};

export default Page;
