import cns from "classnames";

import Image from "@Components/ui/Image";
import Link from "@Components/ui/Link";
import TextIcon from "@Components/ui/TextIcon";
import Video from "@Components/ui/Video";
import { useGetPageDataBannersMiddle } from "@Hooks/api/page";
import { ContentBannersItem } from "@Types/api";

import styles from "./index.module.scss";

type SectionProps = {
    className?: string;
};

const Section: React.FC<SectionProps> = ({ className }) => {
    const dataBannersMiddle = useGetPageDataBannersMiddle();

    if (!dataBannersMiddle.length) {
        return null;
    }

    return (
        <div className={cns(className, styles.component)}>
            {dataBannersMiddle.map(
                (card: ContentBannersItem, cardIndex: never) => (
                    <div key={cardIndex} className={styles.component__card}>
                        <div className={styles.component__media}>
                            {card.media[0].type === "video" && (
                                <Video
                                    src={card.media[0].src}
                                    autoPlayInView
                                    loop
                                    muted
                                    playsInline
                                    preload="none"
                                />
                            )}

                            {card.media[0].type === "image" && (
                                <Image
                                    src={card.media[0].src}
                                    alt=""
                                    fill
                                    size="50vw"
                                    sizeMobile="100vw"
                                />
                            )}
                        </div>

                        <div
                            className={cns(
                                styles.component__title,
                                "text text_h2 text_uppercase"
                            )}
                        >
                            {card.title}
                        </div>

                        {card.text && (
                            <div className={styles.component__text}>
                                {card.text}
                            </div>
                        )}

                        {card.button && (
                            <Link
                                className={styles.component__link}
                                href={card.button.link}
                            >
                                <TextIcon
                                    textClassName={"link"}
                                    icon="angle-right-sm"
                                    isRight
                                >
                                    {card.button.text}
                                </TextIcon>
                            </Link>
                        )}
                    </div>
                )
            )}
        </div>
    );
};

export default Section;
